import { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  UserCircleIcon,
  HandRaisedIcon,
  ArrowDownTrayIcon,
  InformationCircleIcon, // About Us icon added
} from "@heroicons/react/24/outline";

import { motion, AnimatePresence } from "framer-motion";
import img3 from "./image/logo.jpg";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: "Home", href: "/", icon: HomeIcon },
    { name: "Contact Us", href: "/contactus", icon: UserCircleIcon },
    { name: "Partner with Us", href: "/salonform", icon: HandRaisedIcon },
    { name: "About Us", href: "/aboutus", icon: InformationCircleIcon },
    {
      name: "Get the App",
      icon: ArrowDownTrayIcon,
      onClick: () => {
        const userAgent = navigator.userAgent || navigator.vendor;
        const isIOS = /iPad|iPhone|iPod/.test(userAgent);
        const isMac = /Macintosh/.test(userAgent);
        const isAndroid = /android/i.test(userAgent);

        if (isIOS || isMac) {
          window.location.href =
            "https://apps.apple.com/in/app/malon/id6743373588";
        } else if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.maloon&hl=en";
        } else {
          window.open(
            "https://play.google.com/store/apps/details?id=com.maloon&hl=en",
            "_blank"
          );
        }
      },
      href: "#",
    },
  ];

  return (
    <AnimatePresence>
      <motion.header
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        className="inset-x-0 top-0 z-50 bg-[#00A3AD] shadow-sm"
      >
        <nav className="flex items-center justify-between px-6 lg:px-8 max-w-7xl mx-auto h-20">
          {/* Logo */}
          <Link to="/" className="block">
            <div className="flex flex-row items-center space-x-2">
              <img
                src={img3}
                alt="Malon Logo"
                style={{
                  borderRadius: "13px",
                  borderColor: "white",
                  borderWidth: "1px",
                }}
                className="h-12 w-auto drop-shadow-sm"
              />
              <p style={{ fontFamily: "outfit-regular" }} className="text-white">
                Malon
              </p>
            </div>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-8">
            <div className="flex items-center gap-8">
              {navigation.map((item) =>
                item.onClick ? (
                  <button
                    key={item.name}
                    style={{ fontFamily: "outfit-regular" }}
                    onClick={item.onClick}
                    className="relative px-4 py-2 group text-white hover:text-[#e0f7fa] transition-colors flex items-center gap-2"
                  >
                    <item.icon className="h-5 w-5 stroke-[1.5]" />
                    {item.name}
                  </button>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    style={{ fontFamily: "outfit-regular" }}
                    className="relative px-4 py-2 group text-white hover:text-[#e0f7fa] transition-colors flex items-center gap-2"
                  >
                    <item.icon className="h-5 w-5 stroke-[1.5]" />
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="lg:hidden p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <Bars3Icon className="h-7 w-7" />
          </button>
        </nav>

        {/* Mobile Menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="lg:hidden relative z-50"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-out duration-300"
                  enterFrom="translate-y-[-100%]"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in duration-250"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-[-100%]"
                >
                  <Dialog.Panel className="fixed inset-x-0 top-0 w-full">
                    <div className="bg-[#00A3AD] shadow-2xl pt-6 pb-8">
                      <Link style={{ padding: "15px" }} to="/" className="block">
                        <div className="flex flex-row items-center space-x-2">
                          <img
                            src={img3}
                            alt="Malon Logo"
                            style={{
                              borderRadius: "13px",
                              borderColor: "white",
                              borderWidth: "1px",
                            }}
                            className="h-12 w-auto drop-shadow-sm"
                          />
                          <p
                            style={{ fontFamily: "outfit-regular" }}
                            className="text-white"
                          >
                            Malon
                          </p>
                        </div>
                      </Link>

                      <div className="px-6 space-y-4">
                        {navigation.map((item) =>
                          item.onClick ? (
                            <button
                              style={{ fontFamily: "outfit-regular" }}
                              key={item.name}
                              onClick={() => {
                                item.onClick();
                                setMobileMenuOpen(false);
                              }}
                              className="flex items-center gap-3 px-4 py-3 rounded-xl text-white hover:bg-[#008B94] transition-colors"
                            >
                              <item.icon className="h-6 w-6" />
                              {item.name}
                            </button>
                          ) : (
                            <Link
                              key={item.name}
                              to={item.href}
                              onClick={() => setMobileMenuOpen(false)}
                              className="flex items-center gap-3 px-4 py-3 rounded-xl text-white hover:bg-[#008B94] transition-colors"
                            >
                              <item.icon className="h-6 w-6" />
                              {item.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </motion.header>
    </AnimatePresence>
  );
};

export default Navbar;
