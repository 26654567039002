import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Footer from '../Footer';

const ReturnRefundPolicy = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const primaryColor = "#121d23";
  const accentColor = "#00A3AD";

  const sections = [
    {
      title: 'General Policy',
      content: "Malon is a booking platform that connects users with salons. Each salon operates independently and provides its own services. Refunds, cancellations, and modifications are subject to the terms and conditions of each salon."
    },
    {
      title: 'Same-Day Booking Cancellations',
      content: (
        <ul className="list-disc pl-6 space-y-3">
          <li><strong className="font-semibold">Full Refund:</strong> For same-day bookings, cancel within 1 hour of booking to receive a full refund.</li>
          <li><strong className="font-semibold">80% Refund:</strong> If you cancel after 1 hour of booking on the same day, 20% of the booking amount will be deducted.</li>
        </ul>
      )
    },
    {
      title: 'Future Day Booking Cancellations',
      content: (
        <ul className="list-disc pl-6 space-y-3">
          <li><strong className="font-semibold">Full Refund:</strong> For future date bookings, cancel within 6 hours of booking for full refund.</li>
          <li><strong className="font-semibold">Late Cancellations:</strong> Cancellations after 6 hours may incur fees based on salon policies.</li>
        </ul>
      )
    },
    {
      title: 'Refunds',
      content: 'Refunds are processed according to the above conditions. All refunds will be processed within 5-7 business days to original payment method.'
    },
    {
      title: 'Rescheduling Services',
      content: 'Reschedule appointments at least 24 hours in advance. Late requests subject to availability and fees.'
    },
    {
      title: 'Salon-Specific Policies',
      content: 'Please review each salon’s policies before booking through Malon.'
    },
    {
      title: 'Disputes',
      content: 'In case of dispute, Malon’s support will mediate. Final decisions made by the salon.'
    }
  ];

  return (
    <div className="min-h-screen mt-8 bg-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 style={{fontFamily:"outfit-bold"}} className="text-4xl font-outfit-bold text-[#121d23] mb-4">
            Return & Refund Policy
          </h1>
          <div className="w-20 h-1 bg-[#00A3AD] mx-auto rounded-full mb-6" />
          <p style={{fontFamily:"outfit-bold"}} className="text-lg text-[#6B7280] font-outfit-regular">
            Transparent guidelines for your bookings
          </p>
        </div>

        <div className="space-y-4">
          {sections.map((section, index) => (
            <div 
              key={index}
              className="border rounded-xl transition-all duration-200 hover:border-[#00A3AD]/30"
              onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
            >
              <div className={`p-6 cursor-pointer flex justify-between items-center ${expandedIndex === index ? 'bg-gray-50' : ''}`}>
                <h3 style={{fontFamily:"outfit-bold"}} className="text-lg font-outfit-semibold text-[#121d23]">
                  {section.title}
                </h3>
                <ChevronDownIcon 
                  className={`w-5 h-5 text-[#00A3AD] transform transition-transform ${expandedIndex === index ? 'rotate-180' : ''}`}
                />
              </div>
              
              {expandedIndex === index && (
                <div style={{fontFamily:"outfit-regular"}} className="p-6 pt-0 text-[#6B7280] font-outfit-regular animate-slideDown">
                  {section.content}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-12 text-center border-t pt-8">
          <p style={{fontFamily:"outfit-medium"}} className="text-[#6B7280] font-outfit-regular">
            Need help? Contact us at {' '}
            <a 
              href="mailto:support@malon.in" 
              className="text-[#00A3AD] font-outfit-medium hover:underline"
            >
              support@malon.in
            </a>
          </p>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default ReturnRefundPolicy;