import React, { useState } from 'react';
import Footer from '../Footer';
import { db } from '../firebaseconfig';
import { collection, addDoc, doc, getDoc, setDoc } from 'firebase/firestore';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const counterDocRef = doc(db, 'counters', 'contactResponsesCounter');
      const counterDoc = await getDoc(counterDocRef);
      let currentDocId = counterDoc.exists() ? counterDoc.data().count + 1 : 1;
      
      await addDoc(collection(db, 'contactResponses'), {
        ...formData,
        docId: currentDocId,
      });
      
      await setDoc(counterDocRef, { count: currentDocId });

      const response = await fetch('https://website-backend-lake.vercel.app/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error('Email sending failed');

      setShowSuccess(true);
      setFormData({ name: '', email: '', message: '' });
      setTimeout(() => setShowSuccess(false), 4000);
    } catch (error) {
      console.error(error);
      setShowError(true);
      setTimeout(() => setShowError(false), 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen mt-12 flex flex-col bg-white">
      <main className="flex-grow container mx-auto px-4 py-12">
        <div className="max-w-2xl mx-auto bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
          <h2 className="text-3xl font-bold text-[#121d23] mb-8 text-center font-poppins">
            Get in Touch
          </h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-[#121d23] mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#00A3AD] focus:border-[#00A3AD] transition-all"
                placeholder="Your name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#121d23] mb-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#00A3AD] focus:border-[#00A3AD] transition-all"
                placeholder="your@email.com"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-[#121d23] mb-2">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#00A3AD] focus:border-[#00A3AD] transition-all"
                placeholder="Write your message..."
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#00A3AD] hover:bg-[#008c94] text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300 transform hover:scale-[1.02]"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>

          {/* Loading Indicator */}
          {loading && (
            <div className="mt-6 text-center text-gray-600">Submitting your message...</div>
          )}

          {/* Success/Error Messages */}
          {showSuccess && (
            <div className="mt-6 p-4 bg-green-50 border border-green-200 rounded-lg text-green-700">
              Thank you! Your message has been successfully sent.
            </div>
          )}
          
          {showError && (
            <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
              Oops! Something went wrong. Please try again.
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactUs;
