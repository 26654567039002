import React from "react";
import { motion } from "framer-motion";
import { Clock, Scissors, Sparkles, MapPin } from "lucide-react";

const WhyChooseUs = () => {
  const features = [
    {
      icon: <Clock className="w-6 h-6 text-white" />,
      title: "Real-Time Slot Booking",
      description: "Book appointments in 2 clicks with live availability updates"
    },
    {
      icon: <Scissors className="w-6 h-6 text-white" />,
      title: "Quality Verified Salons",
      description: "Curated salons with strict quality standards & verification"
    },
    {
      icon: <Sparkles className="w-6 h-6 text-white" />,
      title: "Exclusive Offers",
      description: "Special discounts & packages for app users"
    },
    {
      icon: <MapPin className="w-6 h-6 text-white" />,
      title: "Smart Location",
      description: "Nearest salons prioritized with real-time tracking"
    }
  ];

  return (
    <div className="bg-white py-24 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-20"
        >
          <h2 style={{fontFamily:"outfit-bold"}} className="text-4xl md:text-5xl text-[#121d23] mb-6">
            Why Choose <span className="text-[#00A3AD]">Malon</span>
          </h2>
          <p style={{fontFamily:"outfit-medium"}} className="text-lg md:text-xl text-[#6B7280] max-w-2xl mx-auto">
            Revolutionizing beauty experiences through intelligent technology 
            and curated partnerships
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.15, duration: 0.5 }}
              whileHover={{ y: -10, scale: 1.02 }}
              className="group relative p-8 bg-gradient-to-br from-white via-[#f7feff] to-white rounded-xl shadow-[0_10px_30px_rgba(0,163,173,0.1)] hover:shadow-[0_15px_40px_rgba(0,163,173,0.2)] transition-all duration-300 border border-white/20"
            >
              {/* Shine overlay */}
              <div className="absolute inset-0 overflow-hidden rounded-xl">
                <div className="absolute -left-full top-0 w-1/2 h-full bg-gradient-to-r from-transparent via-white/30 to-transparent -skew-x-12 group-hover:animate-shine transition-all duration-300" />
              </div>

              {/* Animated border */}
              <div className="absolute inset-0 rounded-xl p-px bg-gradient-to-br from-[#00A3AD]/30 via-[#00A3AD]/10 to-[#00A3AD]/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

              <motion.div
                className="mb-6 flex items-center justify-center w-14 h-14 rounded-xl bg-gradient-to-br from-[#00A3AD] to-[#00C2CB] shadow-lg shadow-[#00A3AD]/30 hover:shadow-[#00A3AD]/50 transition-shadow duration-300"
                whileHover={{ scale: 1.05 }}
              >
                {feature.icon}
              </motion.div>

              <h3 style={{fontFamily:"outfit-semibold"}} className="text-2xl text-[#121d23] mb-4 relative z-10">
                {feature.title}
              </h3>
              <p style={{fontFamily:"outfit-medium"}} className="text-[#6B7280] leading-relaxed relative z-10">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>

      <style jsx global>{`
        @keyframes shine {
          100% {
            left: 200%;
          }
        }
        .animate-shine {
          animation: shine 1.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
      `}</style>
    </div>
  );
};

export default WhyChooseUs;