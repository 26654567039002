// src/component/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Rocket } from 'lucide-react';

const NotFound = () => {
  const gulalColors = ['#FF6B6B', '#4ECDC4', '#FFD93D', '#FF8E8E', '#6C5CE7'];

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '100vh',
        backgroundColor: '#fff', // white background
        color: '#121d23', // dark text color for overall contrast
        textAlign: 'center',
        padding: '20px',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Animated Particles */}
      <AnimatePresence>
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              scale: 0,
              opacity: 1,
              x: Math.random() * 100 - 50,
              y: Math.random() * 100 - 50
            }}
            animate={{
              scale: [0, 1, 0],
              x: Math.random() * 400 - 200,
              y: Math.random() * 400 - 200,
              rotate: Math.random() * 360,
              transition: {
                duration: 3 + Math.random() * 3,
                repeat: Infinity,
                repeatType: "mirror",
                ease: "anticipate"
              }
            }}
            className="absolute w-3 h-3 rounded-full pointer-events-none"
            style={{
              backgroundColor: gulalColors[i % gulalColors.length],
              filter: 'blur(1px)',
              mixBlendMode: 'screen'
            }}
          />
        ))}
      </AnimatePresence>

      {/* Floating 404 Text */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Typography 
          variant="h1" 
          sx={{ 
            fontSize: '8rem', 
            fontWeight: 900, 
            background: 'linear-gradient(45deg, #00A3AD 30%, #6C5CE7 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 2,
            fontFamily: 'outfit-bold'
          }}
        >
          404
        </Typography>
      </motion.div>

      {/* Animated Message */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.6 }}
      >
        <Typography
          variant="h4" 
          sx={{ 
            color: '#4ECDC4',
            mb: 3,
            fontFamily: 'outfit-bold',
            fontSize: '1.8rem'
          }}
        >
          <motion.span
            animate={{ y: [-2, 2, -2] }}
            transition={{ repeat: Infinity, duration: 2 }}
          >
            🚀
          </motion.span> 
          Oops! Lost in Space?
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#121d23', // changed for better contrast on white
            mb: 4,
            fontSize: '1.2rem',
            maxWidth: '600px',
            fontFamily: 'outfit-regular'
          }}
        >
          The page you're looking for seems to have vanished into the digital void. 
          Don't worry, our rocket is ready to take you back home!
        </Typography>
      </motion.div>

      {/* Animated Button */}
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            sx={{ 
              background: 'linear-gradient(45deg, #00A3AD 0%, #6C5CE7 100%)',
              color: '#fff',
              borderRadius: '25px',
              padding: '15px 40px',
              fontSize: '1.1rem',
              fontFamily: 'outfit-bold',
              boxShadow: '0 8px 20px rgba(0, 163, 173, 0.3)',
              '&:hover': {
                boxShadow: '0 12px 25px rgba(0, 163, 173, 0.5)'
              },
              transition: 'all 0.3s ease'
            }}
            startIcon={
              <motion.div
                animate={{ rotate: [0, 360] }}
                transition={{ duration: 4, repeat: Infinity }}
              >
                <Rocket className="animate-pulse" />
              </motion.div>
            }
          >
            Launch Back Home
          </Button>
        </Link>
      </motion.div>

      {/* Floating Stars */}
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-1 h-1 rounded-full"
          style={{
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            backgroundColor: '#121d23' // dark stars for contrast on white
          }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{
            duration: Math.random() * 3 + 1,
            repeat: Infinity,
            delay: Math.random() * 2
          }}
        />
      ))}
    </Box>
  );
};

export default NotFound;
