import * as React from "react";

const faqs = [
  {
    question: "How do I book a service on malon?",
    answer:
      "You can book a service on malon by selecting the desired service, choosing a date and time, and proceeding to checkout for payment.",
  },
  {
    question: "Can I cancel my booking?",
    answer:
      "Yes, you can cancel your booking through the app. Please refer to our cancellation policy for more details.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "Currently, we accept cash payments. In our next update, we will introduce online payment options including credit/debit cards, net banking, and digital wallets.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can contact our customer support team through the app or by email at 'contactus@malon.in'.",
  },
  {
    question: "Do you offer discounts for new users?",
    answer:
      "Yes, we offer discounts and special offers for new users. Keep an eye on our promotions section for the latest deals.",
  },
  {
    question: "Can I schedule appointments in advance?",
    answer:
      "Yes, you can schedule appointments in advance through the app. Select the preferred date and time during the booking process.",
  },
  {
    question: "Are there referral rewards?",
    answer:
      "Currently, we do not have referral rewards. However, we are excited to announce that a referral program with rewards is in the works and will be available in our next update. Stay tuned for more details!",
  },
  {
    question: "How do I apply a coupon code?",
    answer:
      "During checkout, you can enter your coupon code in the designated field to apply discounts to your booking.",
  },
  {
    question: "What happens if I miss my appointment?",
    answer:
      "If you miss your appointment, please contact customer support to reschedule or discuss further options.",
  },
];
export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  return (
    <div className="max-w-7xl mx-auto px-4 py-20 sm:py-24 lg:py-28 bg-white">
      <div className="text-center mb-16">
        <h2 style={{fontFamily:"outfit-bold"}} className="text-4xl font-outfit-bold text-[#121d23] mb-4">
          Frequently Asked Questions
        </h2>
        <div className="h-1.5 w-24 bg-gradient-to-r from-[#00A3AD] to-[#00a3ad70] mx-auto rounded-full" />
      </div>

      <div className="max-w-3xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`border border-[#F3F4F6] rounded-xl transition-all duration-300 
              ${expandedIndex === index 
                ? "bg-[#F8FAFC] border-[#00A3AD] shadow-sm" 
                : "hover:border-[#00A3AD80] hover:shadow-xs"}`}
          >
            <button 
              className="flex justify-between items-center w-full px-5 py-4"
              onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
            >
              <h3 style={{fontFamily:"outfit-semibold"}} className="text-base font-outfit-medium text-[#121d23] text-left pr-4">
                {faq.question}
              </h3>
              <span
                className={`transform transition-transform duration-300 ${
                  expandedIndex === index ? "rotate-180 text-[#121d23]" : "text-[#00A3AD]"
                }`}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>

            <div
              className={`overflow-hidden transition-all duration-300
                ${expandedIndex === index ? "max-h-48 opacity-100" : "max-h-0 opacity-0"}`}
            >
              <div className="px-5 pb-5 pt-1">
                <p style={{fontFamily:"outfit-medium"}} className="text-[#6B7280] text-sm leading-relaxed">
                  {faq.answer}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}