import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { Spa, Brush, Schedule, Star, ArrowForward } from "@mui/icons-material";
import Footer from "../Footer";
import { Link } from "react-router-dom";

// Images

import iosImage2 from "../image/home2.png";
import iosImage3 from "../image/login.png";
import iosImage4 from "../image/salon.png";

// Color scheme
const primaryColor = "#00A3AD";
const darkBackground = "#121d23";
const white = "#ffffff";

// Styled Components
const AboutUsContainer = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(10, 2),
  background: `linear-gradient(135deg, ${white} 0%, #f1f5f9 100%)`,
  display: "flex",
  alignItems: "center",
  color: darkBackground,
}));

const FeatureCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "16px",
  background: white,
  boxShadow: "0 10px 30px rgba(18, 29, 35, 0.05)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-6px)",
    boxShadow: "0 12px 36px rgba(0, 163, 173, 0.2)",
  },
}));

const ImageGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: theme.spacing(2),
  maxWidth: "600px",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1.5),
  },
}));

const ImageCard = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "16px",
  overflow: "hidden",
  boxShadow: "0 8px 24px rgba(18, 29, 35, 0.1)",
  height: "100%", // Ensure the container fills the available height
  "& img": {
    width: "100%",
    height: "100%", // Make the image fill the container
    objectFit: "cover",
    transition: "transform 0.4s ease, filter 0.4s ease",
    filter: "grayscale(20%) brightness(0.95)",
  },
  "&:hover img": {
    transform: "scale(1.05)",
    filter: "grayscale(0%) brightness(1)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "40%",
    background:
      "linear-gradient(180deg, transparent 0%, rgba(18, 29, 35, 0.4) 100%)",
  },
}));

const PlatformBadge = styled(Box)(({ theme }) => ({
  background: `linear-gradient(45deg, ${primaryColor}, #008792)`,
  color: white,
  padding: theme.spacing(1, 2),
  borderRadius: "8px",
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  fontWeight: 600,
  fontSize: "0.95rem",
  boxShadow: "0 4px 12px rgba(0, 163, 173, 0.2)",
}));

const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <AboutUsContainer>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            alignItems="center"
            direction={isMobile ? "column-reverse" : "row"}
          >
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <PlatformBadge style={{ fontFamily: "outfit-bold" }}>
                  <Star fontSize="small" />
                  Premium Salon Platform
                </PlatformBadge>
                <Typography
                  style={{ fontFamily: "outfit-semibold" }}
                  variant="h2"
                  sx={{
                    fontWeight: 800,
                    mb: 3,
                    fontSize: { xs: "2.5rem", md: "3rem" },
                    lineHeight: 1.2,
                    color: darkBackground,
                  }}
                >
                  Discover{" "}
                  <span
                    style={{
                      background: `linear-gradient(135deg, ${primaryColor}, ${darkBackground})`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Exceptional
                  </span>{" "}
                  Beauty Experiences
                </Typography>

                <Typography
                  style={{ fontFamily: "outfit-regular" }}
                  variant="body1"
                  paragraph
                  sx={{
                    color: "#555",
                    fontSize: "1.1rem",
                    lineHeight: 1.7,
                    maxWidth: "600px",
                  }}
                >
                  Malon revolutionizes beauty services by connecting you with
                  certified professionals and premium salons in your city. Enjoy
                  seamless booking and exceptional results.
                </Typography>

                <Grid container spacing={2} sx={{ mt: 4 }}>
                  {[
                    { icon: <Spa />, text: "Real-time Availability" },
                    { icon: <Brush />, text: "Expert Stylists" },
                    { icon: <Star />, text: "Verified Reviews" },
                    { icon: <Schedule />, text: "Instant Confirmation" },
                  ].map((feature, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <FeatureCard>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box
                            sx={{
                              background: `linear-gradient(45deg, ${primaryColor}, #008792)`,
                              borderRadius: "12px",
                              p: 1.5,
                              color: white,
                              minWidth: "56px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {React.cloneElement(feature.icon, {
                              fontSize: "medium",
                            })}
                          </Box>
                          <Typography
                            style={{ fontFamily: "outfit-semibold" }}
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: "1rem",
                              color: darkBackground,
                            }}
                          >
                            {feature.text}
                          </Typography>
                        </Box>
                      </FeatureCard>
                    </Grid>
                  ))}
                </Grid>

                <Box sx={{ mt: 5 }}>
                  <Button
                    style={{ fontFamily: "outfit-semibold" }}
                    variant="contained"
                    endIcon={<ArrowForward />}
                    onClick={() => {
                      const userAgent = navigator.userAgent || navigator.vendor;
                      const isIOS = /iPad|iPhone|iPod/.test(userAgent);
                      const isMac = /Macintosh/.test(userAgent);
                      const isAndroid = /android/i.test(userAgent);

                      if (isIOS || isMac) {
                        window.location.href =
                          "https://apps.apple.com/in/app/malon/id6743373588";
                      } else if (isAndroid) {
                        window.location.href =
                          "https://play.google.com/store/apps/details?id=com.maloon&hl=en";
                      } else {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.maloon&hl=en",
                          "_blank"
                        );
                      }
                    }}
                    sx={{
                      background: `linear-gradient(45deg, ${primaryColor}, #008792)`,
                      color: white,
                      padding: "14px 36px",
                      borderRadius: "12px",
                      fontWeight: 600,
                      fontSize: "1.1rem",
                      textTransform: "none",
                      boxShadow: "0 8px 24px rgba(0, 163, 173, 0.3)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 12px 30px rgba(0, 163, 173, 0.4)",
                      },
                    }}
                  >
                    Explore Salons Nearby
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <ImageGrid>
                <ImageCard>
                  <img src={iosImage2} alt="Hair Styling" />
                </ImageCard>
                <ImageCard>
                  <img src={iosImage3} alt="Spa Treatment" />
                </ImageCard>
                <ImageCard sx={{ display: { xs: "none", sm: "block" } }}>
                  <img src={iosImage4} alt="Booking Process" />
                </ImageCard>
              </ImageGrid>
            </Grid>
          </Grid>
        </Container>
      </AboutUsContainer>
      <Footer />
    </>
  );
};

export default AboutUs;
