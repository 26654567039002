import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Typography, Box, useTheme } from '@mui/material';
import Footer from '../Footer';
import { Policy as PolicyIcon } from '@mui/icons-material';
import { fontFamily } from '@mui/system';

// Using a color palette similar to common professional apps
const colors = {
  primary: '#2A3F5F',    // Dark blue for headings
  secondary: '#00A3AD',  // Medium blue for accents
  accent: '#00C1D4',     // Teal for highlights
  text: '#4A4A4A',       // Dark gray for body text
  lightGray: '#F5F7FA',  // Light gray for backgrounds
  border: '#E0E0E0'      // Border color
};

const privacyPolicyMarkdown = `
# Privacy Policy

## Introduction

Welcome to Malon! This Privacy Policy explains how we collect, use, and share your personal information when you use our Service. By using our Service, you agree to the practices described in this policy.

## Information We Collect

### 1. Personal Information

To provide you with a better experience, we may ask for personal details such as your Name, Email, Contact Number, Date of Birth, and Gender. This information helps us improve our Service.

### 2. Location Data

We collect and use location data to provide location-based services such as suggesting nearby salons or personalizing your app experience based on your location. We do not share your location data with third parties unless required to provide these services or by law.

### 3. Cookies

Cookies are small files that can help improve your experience. While we don’t use cookies directly, third-party services we use might. You can choose to accept or reject cookies through your browser settings. Refusing cookies might affect how some parts of our Service work for you.

## How to Delete Your Data

If you want to delete your information, just email us at [contactus@malon.in](mailto:contactus@malon.in).

## Third-Party Services

Our app might use third-party services, like Facebook, Google, which may collect your information. You can find their privacy policies on their respective websites.

## Service Providers

We may work with other companies to help us run our Service. These companies might have access to your Personal Information, but they are only allowed to use it for the tasks we assign them and must keep it confidential.

## Security

We take your security seriously and use standard methods to protect your information. However, no online method is completely secure, so we can't guarantee absolute protection.

## Data Retention and Deletion Policy

We retain your personal data as long as necessary to provide our services. If you request deletion, we will securely erase your information within a reasonable timeframe.

## Links to Other Sites

Our Service may contain links to other websites. We’re not responsible for their content or privacy practices, so please review their policies if you visit them.

## Changes to This Policy

We may update this Privacy Policy occasionally. Please check this page from time to time for any changes. We’ll notify you of major changes by updating this page.

## Contact Us

If you have any questions or suggestions about our Privacy Policy, feel free to email us at [contactus@malon.in](mailto:contactus@malon.in).
`;

const markdownStyles = {
  h1: {
    fontSize: '2.2rem',
    fontWeight: 700,
    color: colors.primary,
    marginBottom: '25px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    fontFamily:"outfit-bold"
  },
  h2: {
    fontSize: '1.8rem',
    fontWeight: 600,
    color: colors.primary,
    margin: '30px 0 15px',
    paddingBottom: '8px',
    borderBottom: `2px solid ${colors.secondary}`,
    fontFamily:"outfit-semibold"
  },
  h3: {
    fontSize: '1.4rem',
    fontWeight: 500,
    color: colors.secondary,
    margin: '20px 0 10px',
    fontFamily:"outfit-medium"
  },
  p: {
    fontSize: '1rem',
    lineHeight: 1.7,
    color: colors.text,
    marginBottom: '20px',
    fontFamily:"outfit-regular"
  },
  ul: {
    paddingLeft: '25px',
    marginBottom: '25px',
    listStyleType: 'none'
  },
  li: {
    fontSize: '1rem',
    color: colors.text,
    marginBottom: '12px',
    position: 'relative',
    '&:before': {
      content: '"•"',
      color: colors.accent,
      fontWeight: 'bold',
      display: 'inline-block',
      width: '1em',
      marginLeft: '-1em'
    }
  },
  a: {
    color: colors.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: colors.accent,
      textDecoration: 'underline'
    }
  }
};

const PrivacyPolicy = () => {
  const theme = useTheme();

  return (
    <>
    <Box
      sx={{
        backgroundColor: '#ffffff',
        minHeight: '100vh',
        padding: '60px 0 40px'
      }}
    >
      <Container maxWidth="md" sx={{ position: 'relative' }}>
        <Box
          sx={{
            padding: { xs: '25px', md: '40px' },
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '6px',
              background: `linear-gradient(90deg, ${colors.secondary} 0%, ${colors.accent} 100%)`,
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px'
            }
          }}
        >
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <Typography component="h1" sx={markdownStyles.h1}>
                  <PolicyIcon sx={{ fontSize: '2.2rem', color: colors.secondary }} />
                  {props.children}
                </Typography>
              ),
              h2: ({ node, ...props }) => <Typography component="h2" sx={markdownStyles.h2} {...props} />,
              h3: ({ node, ...props }) => <Typography component="h3" sx={markdownStyles.h3} {...props} />,
              p: ({ node, ...props }) => <Typography component="p" sx={markdownStyles.p} {...props} />,
              ul: ({ node, ...props }) => <ul style={markdownStyles.ul} {...props} />,
              li: ({ node, ...props }) => <li style={markdownStyles.li} {...props} />,
              a: ({ node, ...props }) => <a style={markdownStyles.a} {...props} />
            }}
          >
            {privacyPolicyMarkdown}
          </ReactMarkdown>
        </Box>
      </Container>
     
    </Box>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;