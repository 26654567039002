import React from "react";
import ReactMarkdown from "react-markdown";
import { Container, Typography, Box, Link } from "@mui/material";
import Footer from "../Footer";

const TermsandConditionMarkdown = `Terms and condition

Term and condition 1
Term and condition
Terms of Service
Last updated on September 9th, 2024

I. Acceptance of Terms

Thank you for using MALON. These Terms of Service (the "Terms") are intended
to make you aware of your legal rights and responsibilities with respect to your
access to and use of the MALON website at www.malon.in (the "Site") and any
related mobile or software applications ("MALON Platform"), including but not
limited to the delivery of information via the website, whether existing now or in
the future, that link to the Terms (collectively, the "Services").
Please read these Terms carefully. By accessing or using the MALON Platform,
you are agreeing to these Terms and concluding a legally binding contract with
Malon. You may not use the Services if you do not accept the Terms or are unable
to be bound by the Terms. Your use of the MALON Platform is at your own risk,
including the risk that you might be exposed to content that is objectionable or
otherwise inappropriate.

In order to use the Services, you must first agree to the Terms. You can accept the
Terms by:

Clicking to accept or agree to the Terms, where it is made available to you by
MALON in the user interface for any particular Service; or
Actually using the Services. In this case, you understand and agree that
MALON will treat your use of the Services as acceptance of the Terms from
that point onwards.

II. Definitions

Customer
"Customer" or "You" or "Your" refers to you, as a customer of the Services. A
customer is someone who accesses or uses the Services for the purpose of
sharing, displaying, hosting, publishing, transacting, or uploading information,
Term and condition 2

views, or pictures, and includes other persons jointly participating in using the
Services, including without limitation a user having access to a 'Partner business
page' to manage claimed business listings or otherwise.

Content

"Content" will include (but is not limited to) reviews, images, photos, audio, video,
location data, nearby places, and all other forms of information or data. "Your
content" or "Customer Content" means content that you upload, share, or transmit
to, through, or in connection with the Services, such as likes, ratings, reviews,
images, photos, messages, chat communication, profile information, or any other
materials that you publicly display or that are displayed in your account profile.
"MALON Content" means content that MALON creates and makes available in
connection with the Services, including, but not limited to, visual interfaces,
interactive features, graphics, design, compilation, computer code, products,
software, aggregate ratings, reports, and other usage-related data in connection
with activities associated with your account and all other elements and
components of the Services, excluding Your Content and Third Party Content.
"Third Party Content" means content that comes from parties other than MALON
or its Customers and is available on the Services.

Partner(s)

"Partner" means the salons, spas, and all other service outlets listed on the
MALON Platform.

III. Eligibility to Use the Services

You hereby represent and warrant that you are at least thirteen (18) years of age
or above and are fully able and competent to understand and agree to the terms,
conditions, obligations, affirmations, representations, and warranties set forth in
these Terms. Compliance with Laws. You are in compliance with all laws and
regulations in the country in which you live when you access and use the
Services. You agree to use the Services only in compliance with these Terms and
applicable law, and in a manner that does not violate our legal rights or those of
any third party(ies).

IV. Changes to the Terms

MALON may vary, amend, change, or update these Terms from time to time
entirely at its own discretion. You shall be responsible for checking these Terms
Term and condition 3
from time to time and ensuring continued compliance with these Terms. Your use
of the MALON Platform after any such amendment or change in the Terms shall be
deemed as your express acceptance of such amended/changed terms, and you
also agree to be bound by such changed/amended Terms.

V. Translation of the Terms

MALON may provide a translation of the English version of the Terms into other
languages. You understand and agree that any translation of the Terms into other
languages is only for your convenience, and that the English version shall govern
the terms of your relationship with MALON. Furthermore, if there are any
inconsistencies between the English version of the Terms and its translated
version, the English version of the Terms shall prevail.
VI. Provision of the Services Offered by MALON
MALON is constantly evolving to provide the best possible experience and
information to its Customers. You acknowledge and agree that the form and nature
of the Services provided by MALON may require certain changes, and therefore,
MALON reserves the right to suspend, cancel, or discontinue any or all products
or services at any time without notice. MALON may also make modifications and
alterations to any or all of its contents, products, and services contained on the
Site without prior notice.

The software, or the software application store that provides the software, may
include functionality to automatically check for updates or upgrades. Unless your
device or computer software does not permit the transmission or use of upgrades
or updates, you agree that MALON, or the applicable software or software
application store, may notify you of the availability of such upgrades or updates
and may automatically push such upgrades or updates to your device or computer
from time to time. You may be required to install certain upgrades or updates to
continue accessing or using the Services, or portions thereof (including upgrades
or updates designed to correct issues with the Services). Any updates or
upgrades provided to you by MALON under these Terms shall be considered part
of the Services.

You acknowledge and agree that if MALON disables access to your account, you
may be prevented from accessing the Services, your account details, or any files

Term and condition 4

or other content contained in your account. You also acknowledge and agree that
while MALON may not currently set a fixed upper limit on the number of
transmissions you may send or receive through the Services, MALON may set
such fixed upper limits at any time, at its discretion.
In our effort to continuously improve the MALON Platform and Services, we may
conduct research and experiments on various aspects of the Services and
offerings, including our apps, websites, user interface, and promotional
campaigns. As a result, some Customers may experience features differently than
others at any given time. This is done to make the MALON Platform better, more
convenient, and easy to use, to improve Customer experience, enhance the safety
and security of our services, and develop new services and features.
By using MALON's Services, you agree to the following disclaimers:
The Content on these Services is for informational purposes only. MALON
disclaims any liability for any information that may have become outdated
since the last update.

MALON reserves the right to make changes and corrections to any part of the
Content on these Services at any time without prior notice.
MALON does not guarantee the quality of the service, goods, prices listed in
rate cards, or the availability of all rate card items at any Partner.
Unless stated otherwise, all pictures and information contained on these
Services are believed to be owned by or licensed to MALON. If you believe any
Content on these Services infringes your copyright, please email a takedown
request (using the "Contact Us" link on the home page) to the webmaster,
including the exact URL of the webpage in your request.
All images shown here have been digitized by MALON. No other party is
authorized to reproduce or republish these digital versions in any format
without MALON's prior written permission.

Any certification, licenses, or permits ("Certification") displayed on the
Partner's listing page on the MALON Platform are for informational purposes
only. Such Certification is displayed on an 'as available' basis provided to
MALON by the Partners. MALON does not make any warranties about the
validity, authenticity, reliability, or accuracy of such Certification or information
Term and condition 5

displayed in this regard. Any reliance on the Certification or information is at
the Customer's own risk, and MALON assumes no liability for any losses or
damages in connection with the use of this information or for any inaccuracy,
invalidity, or discrepancy in the Certification or non-compliance with
applicable local laws or regulations by the Partner.
MALON reserves the right to charge a subscription and/or membership and/or
convenience fee from a Customer with reasonable prior notice, for any
product, service, or other aspect of the MALON Platform at any time in the
future.

MALON may introduce referral and/or incentive-based programs for its
Customers ("Program"). These Programs may have their own terms and
conditions. By participating in the Program, Customers agree to the Program
terms and conditions as well as the MALON Platform terms. MALON reserves
the right to terminate or suspend the Customer's account, credits or points
earned, or participation in the Program if MALON determines, at its sole
discretion, that the Customer has violated the Program rules or engaged in
fraudulent or unlawful activities. MALON also reserves the right to modify,
cancel, or discontinue its Program without notice.

VII. Content

1. Ownership of MALON Content and Proprietary Rights
a. We are the sole and exclusive copyright owners of the Services and our
Content. We also exclusively own the copyrights, trademarks, service marks,
logos, trade names, trade dress, and other intellectual and proprietary rights
throughout the world (the "IP Rights") associated with the Services and
MALON Content, which may be protected by copyright, patent, trademark, and
other applicable intellectual property and proprietary rights and laws. You
acknowledge that the Services contain original works and have been
developed, compiled, prepared, revised, selected, and arranged by us and
others through substantial time, effort, and investment, and constitute valuable
intellectual property. You further acknowledge that the Services may contain
information designated as confidential by MALON, and you shall not disclose
such information without MALON's prior written consent.

Term and condition 6

b. You agree to protect MALON's proprietary rights and the proprietary rights
of all others having rights in the Services during and after the term of this
agreement, and to comply with all reasonable written requests made by us or
our suppliers and licensors of content to protect their rights. You acknowledge
and agree that MALON (or MALON's licensors) own all legal rights, title, and
interest in and to the Services, including any IP Rights which subsist in the
Services. You further acknowledge that the Services may contain information
designated as confidential by MALON, and you shall not disclose such
information without MALON's prior written consent. Unless agreed otherwise
in writing with MALON, nothing in these Terms gives you a right to use any of
MALON's trade names, trademarks, service marks, logos, domain names, or
other distinctive brand features.

c. You agree not to use any framing techniques to enclose any trademark,
logo, or other proprietary information of MALON; or remove, conceal, or
obliterate any copyright or other proprietary notice or source identifier. Any
infringement may lead to legal proceedings to seek all available remedies
under applicable laws. You cannot modify, reproduce, publicly display, or
exploit any of MALON's Content except as expressly authorized by MALON.

d. To the fullest extent permitted by applicable law, we neither warrant nor
represent that your use of materials displayed on the Services will not infringe
the rights of third parties not owned by or affiliated with us. You agree to notify
us immediately upon becoming aware of any claim that the Services infringe
upon any copyright, trademark, or other rights.

2. Your License to MALON Content

a. We grant you a personal, limited, non-exclusive, and non-transferable
license to access and use the Services only as expressly permitted in these
Terms. You shall not use the Services for any illegal purpose or in any manner
inconsistent with these Terms. You may use information made available
through the Services solely for personal, non-commercial use. You agree not
to use, copy, display, distribute, modify, broadcast, translate, reproduce,
reformat, incorporate into advertisements and other works, sell, promote,
create derivative works, or exploit MALON Content except as expressly
authorized by us. Except as otherwise expressly granted to you in writing, we

Term and condition 7

do not grant any other express or implied right or license to the Services,
MALON Content, or our IP Rights.
b. Any violation of the license provisions may result in the immediate
termination of your right to use the Services and potential liability for copyright
and other IP Rights infringement.

3. Malon License to Your or Customer Content

In consideration of availing the Services on the Malon Platform and by
submitting Your Content, you hereby irrevocably grant Malon a perpetual,
irrevocable, world-wide, non-exclusive, fully paid and royalty-free, assignable,
sub-licensable, and transferable license and right to use Your Content
(including content shared by any business user having access to a 'Partner
business page' to manage claimed business listings or otherwise) and all IP
Rights therein for any purpose including API partnerships with third parties and
in any media existing now or in future. By "use" we mean use, copy, display,
distribute, modify, translate, reformat, incorporate into advertisements and
other works, analyze, promote, commercialize, create derivative works, and in
the case of third-party services, allow their users and others to do the same.
You grant us the right to use the name or username that you submit in
connection with Your Content. You irrevocably waive, and cause to be waived,
any claims and assertions of moral rights or attribution with respect to Your
Content brought against Malon or its Customers, any third-party services, and
their users.

4. Representations Regarding Your or Customer Content

a. Responsibility for Content: You are responsible for Your Content. You
represent and warrant that you are the sole author of, own, or otherwise
control all of the rights of Your Content or have been granted explicit
permission from the rights holder to submit Your Content; Your Content was
not copied from or based in whole or in part on any other content, work, or
website; Your Content was not submitted via the use of any automated
process such as a script bot; use of Your Content by us, third-party services,
and our and any third-party users will not violate or infringe any rights of yours
Term and condition 8
or any third party; Your Content is truthful and accurate; and Your Content
does not violate the Guidelines and Policies or any applicable laws.
b. Review Content: If Your Content is a review, you represent and warrant that
you are the sole author of that review; the review reflects an actual experience
that you had; you were not paid or otherwise remunerated in connection with
your authoring or posting of the review; and you had no financial, competitive,
or other personal incentive to author or post a review that was not a fair
expression of your honest opinion.

c. Risks and Liability: You assume all risks associated with Your Content,
including anyone's reliance on its quality, accuracy, or reliability, or any
disclosure by you of information in Your Content that makes you personally
identifiable. While we reserve the right to remove Content, we do not control
actions or Content posted by our Customers and do not guarantee the
accuracy, integrity or quality of any Content. You acknowledge and agree that
Content posted by Customers and any and all liability arising from such
Content is the sole responsibility of the Customer who posted the content, and
not Malon.
5. Content Removal

We reserve the right, at any time and without prior notice, to remove, block, or
disable access to any Content that we, for any reason or no reason, consider
to be objectionable, in violation of the Terms or otherwise harmful to the
Services or our Customers in our sole discretion. Subject to the requirements
of applicable law, we are not obligated to return any of Your Content to you
under any circumstances. Further, the Partner reserves the right to delete any
images and pictures forming part of Customer Content, from such Partner's
listing page at its sole discretion.

6. Third Party Content and Links

a. Third-Party Materials: Some of the content available through the Services
may include or link to materials that belong to third parties, such as third-party
reservation services. Please note that your use of such third-party services
will be governed by the terms of service and privacy policy applicable to the
corresponding third party. We may obtain business addresses, phone
Term and condition 9
numbers, and other contact information from third-party vendors who obtain
their data from public sources.

b. No Endorsement: We have no control over, and make no representation or
endorsement regarding the accuracy, relevancy, copyright compliance,
legality, completeness, timeliness or quality of any product, services,
advertisements and other content appearing in or linked to from the Services.
We do not screen or investigate third-party material before or after including it
on our Services.

c. Content Improvements: We reserve the right, in our sole discretion and
without any obligation, to make improvements to, or correct any error or
omissions in, any portion of the content accessible on the Services. Where
appropriate, we may in our sole discretion and without any obligation, verify
any updates, modifications, or changes to any content accessible on the
Services, but shall not be liable for any delay or inaccuracies related to such
updates. You acknowledge and agree that Malon is not responsible for the
availability of any such external sites or resources, and does not endorse any
advertising, products or other materials on or available from such websites or
resources.

d. No Responsibility: Third-party content, including content posted by our
Customers, does not reflect our views or that of our parent, subsidiary, affiliate
companies, branches, employees, officers, directors, or shareholders. In
addition, none of the content available through the Services is endorsed or
certified by the providers or licensors of such third-party content. We assume
no responsibility or liability for any of Your Content or any third-party content.

e. External Site Liability: You further acknowledge and agree that Malon is not
liable for any loss or damage which may be incurred by you as a result of the
availability of those external sites or resources, or as a result of any reliance
placed by you on the completeness, accuracy or existence of any advertising,
products or other materials on, or available from, such websites or resources.
Without limiting the generality of the foregoing, we expressly disclaim any
liability for any offensive, defamatory, illegal, invasive, unfair, or infringing
content provided by third parties.

7. Customer Reviews

Term and condition 10
a. Review Disclaimers: Customer reviews or ratings for Partners do not reflect
the opinion of Malon. Malon receives multiple reviews or ratings for Partners
by Customers, which reflect the opinions of the Customers. It is pertinent to
state that each and every review posted on Malon is the personal opinion of
the Customer/reviewer only. Malon is a neutral platform, which solely provides
a means of communication between Customers/reviewers including
Customers or Partner owners/representatives with access to Partner business
page. The advertisements published on the Malon Platform are independent of
the reviews received by such advertisers.

b. Dispute Resolution: We are a neutral platform and we don't arbitrate
disputes, however in case if someone writes a review that the Partner does not
consider to be true, the best option for the Partner representative would be to
contact the reviewer or post a public response in order to clear up any
misunderstandings. If the Partner believes that any particular Customer's
review violates any of Malon's policies, the Partner may write to us at
info@salonsurf.in and bring such violation to our attention. Malon may remove
the review in its sole discretion if the review is in violation of the Terms, or
content guidelines and policies or otherwise harmful to the Services.

VIII. Content Guidelines and Privacy Policy

1. Content Guidelines: You represent that you have read, understood and
agreed to our Guidelines and Policies related to Content.

2. Privacy Policy: You represent that you have read, understood and agreed
to our Privacy Policy. Please note that we may disclose information about
you to third parties or government authorities if we believe that such a
disclosure is reasonably necessary to (i) take action regarding suspected
illegal activities; (ii) enforce or apply our Terms and Privacy Policy; (iii)
comply with legal process or other government inquiry, such as a search
warrant, subpoena, statute, judicial proceeding, or other legal
process/notice served on us; or (iv) protect our rights, reputation, and
property, or that of our Customers, affiliates, or the general public.

IX. Restrictions on Use

Term and condition 11
Without limiting the generality of these Terms, in using the Services, you
specifically agree not to post or transmit any content (including review) or
engage in any activity that, in our sole discretion:
a. Violates our Guidelines and Policies.

b. Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory,
discriminatory, vulgar, profane, obscene, libellous, hateful or otherwise
objectionable, invasive of another's privacy, relating or encouraging money
laundering or gambling.

c. Constitutes an inauthentic or knowingly erroneous review, or does not
address the goods and services, atmosphere, or other attributes of the
business you are reviewing.

d. Contains material that violates the standards of the Services.

e. Violates any third-party right, including, but not limited to, right of privacy,
right of publicity, copyright, trademark, patent, trade secret, or any other
intellectual property or proprietary rights.

f. Accuses others of illegal activity, or describes physical confrontations.

g. Alleges any matter related to health code violations requiring healthcare
department reporting.

h. Is illegal, or violates any federal, state, or local law or regulation (for
example, by disclosing or trading on inside information in violation of
securities law).

i. Attempts to impersonate another person or entity.

j. Disguises or attempts to disguise the origin of Your Content, including but
not limited to by: (i) submitting Your Content under a false name or false
pretenses; or (ii) disguising or attempting to disguise the IP address from
which Your Content is submitted.

k. Constitutes a form of deceptive advertisement or causes, or is a result of, a
conflict of interest.

l. Is commercial in nature, including but not limited to spam, surveys, contests,
pyramid schemes, postings or reviews submitted or removed in exchange for
payment, postings or reviews submitted or removed by or at the request of the
business being reviewed, or other advertising materials.

Term and condition 12

m. Asserts or implies that Your Content is in any way sponsored or endorsed
by us.

n. Contains material that is not in English or, in the case of products or
services provided in foreign languages, the language relevant to such
products or services.

o. Falsely states, misrepresents, or conceals your affiliation with another
person or entity.

p. Accesses or uses the account of another customer without permission.


q. Distributes computer viruses or other code, files, or programs that interrupt,
destroy, or limit the functionality of any computer software or hardware or
electronic communications equipment.

r. Interferes with, disrupts, or destroys the functionality or use of any features
of the Services or the servers or networks connected to the Services.

s. "Hacks" or accesses without permission our proprietary or confidential
records, records of another Customer, or those of anyone else.

t. Violates any contract or fiduciary relationship (for example, by disclosing
proprietary or confidential information of your employer or client in breach of
any employment, consulting, or non-disclosure agreement).

u. Decompiles, reverse engineers, disassembles or otherwise attempts to
derive source code from the Services.

v. Removes, circumvents, disables, damages or otherwise interferes with
security-related features, or features that enforce limitations on use of, the
Services.

w. Violates the restrictions in any robot exclusion headers on the Services, if
any, or bypasses or circumvents other measures employed to prevent or limit
access to the Services.

x. Collects, accesses, or stores personal information about other Customers of
the Services.

y. Is posted by a bot.

z. Harms minors in any way.

Term and condition 13

aa. Threatens the unity, integrity, defense, security or sovereignty of India or
of the country of use, friendly relations with foreign states, or public order or
causes incitement to the commission of any cognizable offence or prevents
investigation of any offence or is insulting any other nation.

ab. Modifies, copies, scrapes or crawls, displays, publishes, licenses, sells,
rents, leases, lends, transfers or otherwise commercializes any rights to the
Services or Our Content; or

ac. Attempts to do any of the foregoing.

ad. Is patently false and untrue, and is written or published in any form, with
the intent to mislead or harass a person, entity or agency for financial gain or
to cause any injury to any person.
You acknowledge that Malon has no obligation to monitor your or anyone
else's access to or use of the Services for violations of the Terms, or to review
or edit any content. However, we have the right to do so for the purpose of
operating and improving the Services (including without limitation for fraud
prevention, risk assessment, investigation and customer support purposes), to
ensure your compliance with the Terms and to comply with applicable law or
the order or requirement of legal process, a court, consent decree,
administrative agency or other governmental body. You hereby agree and
assure Malon that the Malon Platform/Services shall be used for lawful
purposes only and that you will not violate laws, regulations, ordinances or
other such requirements of any applicable Central, Federal State or local
government or international law(s). You shall not upload, post, email, transmit
or otherwise make available any unsolicited or unauthorized advertising,
promotional materials, junk mail, spam mail, chain letters or any other form of
solicitation, encumber or suffer to exist any lien or security interest on the
subject matter of these Terms or to make any representation or warranty on
behalf of Malon in any form or manner whatsoever. You hereby agree and
assure that while communicating on the Malon Platform including but not
limited to giving cooking instructions to the Partners, communicating with our
support agents on chat support or with the Delivery Partners, through any
medium, you shall not use abusive and derogatory language and/or post any
objectionable information that is unlawful, threatening, defamatory, or
obscene. In the event you use abusive language and/or post objectionable
Term and condition 14
information, Malon reserves the right to suspend the chat support service
and/or block your access and usage of the Malon Platform, at any time with or
without any notice. Any Content uploaded by you, shall be subject to relevant
laws of India and of the country of use and may be disabled, or and may be
subject to investigation under applicable laws. Further, if you are found to be in
non-compliance with the laws and regulations, these terms, or the privacy
policy of the Malon Platform, Malon shall have the right to immediately block
your access and usage of the Malon Platform and Malon shall have the right to
remove any non-compliant content and or comment forthwith, uploaded by
you and shall further have the right to take appropriate recourse to such
remedies as would be available to it under various statutes.

X. Customer Feedback

If you share or send any ideas, suggestions, changes or documents regarding
Malon's existing business ("Feedback"), you agree that (i) your Feedback does
not contain confidential, secretive or proprietary information of third parties,
(ii) Malon is under no obligation of confidentiality with respect to such
Feedback, and shall be free to use the Feedback on an unrestricted basis (iii)
Malon may have already received similar Feedback from some other Customer
or it may be under consideration or in development, and (iv) By providing the
Feedback, you grant us a binding, non-exclusive, royalty-free, perpetual,
global license to use, modify, develop, publish, distribute and sublicense the
Feedback, and you irrevocably waive, against Malon and its Customers any
claims/assertions, whatsoever of any nature, with regard to such Feedback.
Please provide only specific Feedback on Malon's existing products or
marketing strategies; do not include any ideas that Malon's policy will not
permit it to accept or consider. Notwithstanding the abovementioned clause,
Malon or any of its employees do not accept or consider unsolicited ideas,
including ideas for new advertising campaigns, new promotions, new or
improved products or technologies, product enhancements, processes,
materials, marketing plans or new product names. Please do not submit any
unsolicited ideas, original creative artwork, suggestions or other works
("Submissions") in any form to Malon or any of its employees. The purpose of
this policy is to avoid potential misunderstandings or disputes when Malon's
products or marketing strategies might seem similar to ideas submitted to
Term and condition 15

Malon. If, despite our request to not send us your ideas, you still submit them,
then regardless of what your letter says, the following terms shall apply to your
Submissions. Terms of Idea Submission: You agree that: (1) your Submissions
and their Contents will automatically become the property of Malon, without
any compensation to you; (2) Malon may use or redistribute the Submissions
and their contents for any purpose and in any way; (3) there is no obligation
for Malon to review the Submission; and (4) there is no obligation to keep any
Submissions confidential.

XI. Advertising

Some of the Services are supported by advertising revenue and may display
advertisements and promotions. These advertisements may be targeted to the
content of information stored on the Services, queries made through the
Services, or other information. The manner, mode and extent of advertising by
Malon on the Services are subject to change without specific notice to you. In
consideration for Malon granting you access to and use of the Services, you
agree that Malon may place such advertising on the Services. Part of the site
may contain advertising information or promotional material or other material
submitted to Malon by third parties or Customers. Responsibility for ensuring
that material submitted for inclusion on the Malon Platform or mobile apps
complies with applicable international and national law is exclusively on the
party providing the information/material. Your correspondence or business
dealings with, or participation in promotions of, advertisers other than Malon
found on or through the Malon Platform and or mobile apps, including payment
and delivery of related goods or services, and any other terms, conditions,
warranties or representations associated with such dealings, shall be solely
between you and such advertiser. Malon will not be responsible or liable for
any error or omission, inaccuracy in advertising material or any loss or damage
of any sort incurred as a result of any such dealings or as a result of the
presence of such other advertiser(s) on the Malon Platform and mobile
application.

XII. Additional Terms and Conditions for Customers Using the
Various Services Offered by Malon

Term and condition 16
I. APPOINTMENT BOOKING SERVICE:

a. Booking Appointments:

Customers can request to book an appointment for services at a Partner
via the Malon Platform and related mobile or software application.
Appointment confirmations will be sent via SMS and/or other
communication methods. Availability is determined at the time of the
request. By submitting a booking request, you accept Malon's terms and
privacy policies and agree to receive booking confirmations via email,
SMS, and/or other communication methods. Customers agree not to make
more than one reservation for personal use for the same appointment time.

b. Modifications & Cancellations:

Any request to modify a confirmed booking will depend on the Partner's
acceptance. Malon will make reasonable efforts to keep Customers
informed of the booking status.

c. Late Arrivals:

Customers are advised to arrive 10 minutes before the scheduled booking
time. The Partner reserves the right to cancel the booking and allocate the
appointment to others in case of late arrivals. Malon is not liable for
cancellations initiated by the Partner.
d. Dispute Resolution:

If a Partner fails to honor a confirmed booking or if there is a complaint or
dispute, the Customer must raise the issue with Malon within 30 minutes
from the scheduled booking time at the provided helpline numbers. Malon
will verify the facts with the Partner and attempt to resolve the issue.

e. Personal Information:

Customers must share personal information (e.g., name, phone number,
email address) with Malon and/or the Partner to use the booking service.
Malon will share this information with the Partner for booking confirmation
and related communication. Malon is not liable for the Partner's use of
your personal information beyond the scope of the booking service.
f. Additional Requests:
Term and condition 17
Any additional requests made during booking will be conveyed to the
Partner by Malon and confirmed based on the Partner's response. Malon
will communicate these requests timely, but fulfilling them is the Partner's
responsibility. Malon is not liable if the Partner does not honor additional
requests.
g. Call Recording:

Malon may contact Customers via telephone, SMS, email, or other
methods regarding their appointment booking or feedback. Calls related to
booking requests may be recorded for internal training and quality
purposes.

h. Liability Limitation:

Malon is not liable for in-person interactions with the Partner, the
Customer's experience, or if a Partner does not honor a confirmed
booking. Malon merely connects Customers to Partners and is not
responsible for the Partner's acts, omissions, service quality, or other
experiences.

i. Contact Us:

For queries regarding the Malon appointment booking service, you may
write to us at info@malon.app or contact us at +919619788939.

XIII. DISCLAIMER OF WARRANTIES, LIMITATION OF LIABILITY,
AND INDEMNIFICATION

1. Disclaimer of Warranties:
You acknowledge and agree that the Services are provided "as is" and
"as available." To the fullest extent permitted by applicable law, Malon
and its affiliates disclaim all warranties, express or implied, related to
the Services. Malon does not guarantee the accuracy or completeness
of content or assume responsibility for:

(i) Errors or inaccuracies of content

(ii) Personal injury or property damage resulting from use of the
Services

Term and condition 18

(iii) Unauthorized access to personal information

(iv) Interruption or cessation of service

(v) Bugs or viruses transmitted through the Services

(vi) Loss of data or content

(vii) Errors or omissions in content

Any material downloaded through the Services is at your own risk.
Malon is not responsible for monitoring transactions with third-party
providers or for interactions with other Customers.
2. Limitation of Liability:

To the fullest extent permitted by law, Malon will not be liable for any
damages resulting from:

(i) Errors or inaccuracies of content

(ii) Personal injury or property damage

(iii) Unauthorized access to personal information

(iv) Interruption of service

(v) Bugs or viruses

(vi) Loss of data

(vii) Errors or omissions in content

Malon is not liable for indirect, incidental, special, punitive, or
consequential damages, including loss of profit, goodwill, data, or
costs incurred.
3. Indemnification:

You agree to indemnify, defend, and hold harmless Malon and its
affiliates from any third-party claims, damages, actions, or losses
arising from:
(i) Your content

(ii) Unauthorized use of the Services

(iii) Your access to and use of the Services

Term and condition 19
(iv) Violation of third-party rights

(v) Breach of these Terms

Malon reserves the right to assume exclusive defense of any matter for
which you are required to indemnify, at your expense. You agree not to
settle any matter without Malon's prior written consent.
XIV. TERMINATION OF ACCESS TO THE SERVICES
Malon may terminate your access to the Services at its sole discretion, for
any reason, including violation of these Terms or lack of use. Termination
may occur without prior notice, and Malon may deactivate or delete your
account and related information. Malon is not liable for any loss or damage
resulting from the termination of access.

XV. GENERAL TERMS

1. Interpretation:

Section and subject headings are for reference only and do not affect
the interpretation of these Terms.

2. Entire Agreement and Waiver:

These Terms, together with the Privacy Policy, constitute the entire
agreement between you and Malon. No failure or delay in exercising
any right under these Terms constitutes a waiver of that right.

3. Severability:

If any provision of these Terms is deemed unlawful or unenforceable, it
will be severed, and the remainder will continue in full force.

4. Partnership or Agency:

These Terms do not create a partnership or agency relationship
between you and Malon.

5. Governing Law/Waiver:

(a) For Customers in India: The Terms are governed by Indian law, and
Mumbai courts have exclusive jurisdiction.

Term and condition 20

(b) For all Customers: Legal actions must be commenced within one
year of the alleged harm. Any failure to enforce or exercise any
provision does not constitute a waiver of that provision.

6. Carrier Rates:

Accessing the Services via mobile may incur charges by your Internet
or mobile service provider.

7. Linking and Framing:

Linking to the Services is permitted, but framing is not allowed. Links
should not direct to inappropriate or unlawful content.

XVI. NOTICE OF COPYRIGHT INFRINGEMENT

Malon is not liable for copyright infringement arising from user-posted
materials. If you believe your copyright has been infringed, follow the
procedure below to file a notice:

(i) Identify the copyrighted material and the alleged infringing material.

(ii) Provide a statement of good faith belief and accuracy under penalty
of perjury.

(iii) Include your contact information and signature.

(iv) Send the notice to info@malon.app.

XVII. CONTACT US

1. Company Details:
Legal Entity Name: Malon
Address: Kiit university
Website: www.malon.in
Contact Email: contactus@malon.in

Refund Policy:

Refunds are considered in the following scenarios:
Term and condition 21
If the user has cancelled the booking in the given time then the full
refund will be initiated within 24 hour and payment will be credited
within 7 days.
If the salon has charged more price then the price shown in the app
then the amount charged more will be returned.
Customer has paid more amount then the amount given in the app.`; // Keep your existing markdown content

const markdownStyles = {
  h1: {
    fontSize: "2.2rem",
    fontWeight: 700,
    color: "#121d23",
    marginBottom: "30px",
    paddingBottom: "15px",
    borderBottom: "2px solid #00A3AD",
    textAlign: "center",
    fontFamily: "outfit-bold",
  },
  h2: {
    fontSize: "1.8rem",
    fontWeight: 600,
    color: "#121d23",
    margin: "25px 0 15px",
    fontFamily: "outfit-semibold",
  },
  p: {
    fontSize: "1rem",
    lineHeight: 1.7,
    color: "#424242",
    marginBottom: "20px",
    fontFamily: "outfit-medium",
  },
  ul: {
    paddingLeft: "30px",
    marginBottom: "25px",
  },
  li: {
    fontSize: "1rem",
    color: "#424242",
    marginBottom: "8px",
    lineHeight: 1.6,
  },
  strong: {
    color: "#121d23",
    fontWeight: 600,
  },
  a: {
    color: "#00A3AD",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const TermsandCondition = () => {
  return (
    <>
    <Box
      sx={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        padding: "60px 0 40px",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            padding: { xs: "30px", md: "50px" },
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 8px 32px rgba(18, 29, 35, 0.08)",
            position: "relative",
            "&:before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "4px",
              backgroundColor: "#00A3AD",
              borderRadius: "8px 0 0 8px",
            },
          }}
        >
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <Typography component="h1" sx={markdownStyles.h1} {...props} />
              ),
              h2: ({ node, ...props }) => (
                <Typography component="h2" sx={markdownStyles.h2} {...props} />
              ),
              p: ({ node, ...props }) => (
                <Typography component="p" sx={markdownStyles.p} {...props} />
              ),
              ul: ({ node, ...props }) => (
                <ul style={markdownStyles.ul} {...props} />
              ),
              li: ({ node, ...props }) => (
                <li style={markdownStyles.li} {...props} />
              ),
              strong: ({ node, ...props }) => (
                <strong style={markdownStyles.strong} {...props} />
              ),
              a: ({ node, ...props }) => (
                <Link sx={markdownStyles.a} {...props} />
              ),
            }}
          >
            {TermsandConditionMarkdown}
          </ReactMarkdown>

          {/* Refund Policy Highlight */}
          <Box
            sx={{
              backgroundColor: "#f5fbfc",
              borderLeft: "4px solid #00A3AD",
              padding: "25px",
              borderRadius: "4px",
              margin: "40px 0",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: "1.4rem",
                fontWeight: 600,
                color: "#121d23",
                mb: 2,
                fontFamily: "'Outfit', sans-serif",
              }}
            >
              Refund Policy
            </Typography>
            <Typography sx={markdownStyles.p}>
              Refunds are considered in the following scenarios:
              <br />
              • If the user has cancelled the booking in the given time
              <br />
              • If the salon has charged more than the price shown in the app
              <br />• Customer has paid more amount than specified in the app
            </Typography>
          </Box>
        </Box>
      </Container>
      
    </Box>
      
      <Footer/> 

    </>
  );
};

export default TermsandCondition;
