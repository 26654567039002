import React from "react";

import Typography from "@mui/material/Typography";

import WhyChooseus from "./Whychooseus";
import Ourmission from "./Ourmission";
import MalonPage from "./MalonPage";
import AboutUs from "./About";




function Ourservices() {
  return (
    <>
      
        <div style={{ background: '#000'}}>
          
          
          <Typography>
            
            <WhyChooseus/>
          </Typography>
           <MalonPage/>
          <Typography>
            <Ourmission/>
          </Typography>
          
          
          
        </div>
        </>
        
    
  );
}
export default Ourservices;
