import React from "react";
import { motion } from "framer-motion";
import { GiScissors } from "react-icons/gi";
import { FaCalendarAlt, FaStar, FaGift, FaLightbulb, FaHeadset } from "react-icons/fa";

const missionData = [
  {
    title: "All Salons in One Place",
    description: "Comprehensive listing of all salons in your area with easy booking",
    icon: GiScissors,
  },
  {
    title: "Seamless Scheduling",
    description: "Easy-to-use interface for hassle-free appointments",
    icon: FaCalendarAlt,
  },
  {
    title: "Trusted Reviews",
    description: "Genuine ratings and reviews from real users",
    icon: FaStar,
  },
  {
    title: "Exclusive Offers",
    description: "Special discounts available only on our platform",
    icon: FaGift,
  },
  {
    title: "Expert Recommendations",
    description: "Personalized suggestions from beauty experts",
    icon: FaLightbulb,
  },
  {
    title: "24/7 Support",
    description: "Round-the-clock customer assistance",
    icon: FaHeadset,
  },
];

const MissionPage = () => {
  return (
    <div className="min-h-screen bg-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16 relative"
        >
          <h2 style={{ fontFamily: "outfit-bold" }} className="text-3xl md:text-4xl text-[#121d23] mb-4">
            Our Core Mission
          </h2>
          <p style={{ fontFamily: "outfit-medium" }} className="text-lg text-[#6B7280] max-w-2xl mx-auto relative">
            Transforming beauty experiences through innovative solutions
            <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-32 h-0.5 bg-[#00A3AD]/20 animate-pulse" />
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {missionData.map((mission, index) => {
            const IconComponent = mission.icon;
            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="group bg-white p-6 rounded-xl border border-[#f0f0f0]
                  shadow-sm hover:shadow-md transition-all duration-300
                  hover:-translate-y-1 relative overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-white to-[#f8fcff] opacity-0 group-hover:opacity-100 transition-opacity" />
                
                <div className="relative z-10">
                  <div className="mb-4 flex items-center gap-4">
                    <div className="p-3 bg-gradient-to-br from-[#00A3AD] to-[#00C2CB] rounded-lg shadow-sm">
                      <IconComponent className="text-3xl text-white" />
                    </div>
                    <h3 style={{ fontFamily: "outfit-semibold" }} className="text-xl text-[#121d23]">
                      {mission.title}
                    </h3>
                  </div>
                  
                  <p style={{ fontFamily: "outfit-medium" }} className="text-[#6B7280] text-base leading-relaxed pl-4 border-l-2 border-[#00A3AD]/30 ml-3">
                    {mission.description}
                  </p>

                  <div className="mt-4 relative">
                    <div className="w-full h-0.5 bg-[#f0f0f0] rounded-full overflow-hidden">
                      <div className="w-0 group-hover:w-full h-full bg-gradient-to-r from-[#00A3AD] to-[#00747D] transition-all duration-500 ease-out" />
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          className="mt-16 text-center"
        >
          <p style={{ fontFamily: "outfit-medium" }} className="text-[#6B7280] italic max-w-3xl mx-auto">
            "Empowering beauty enthusiasts with seamless digital solutions that connect 
            <span style={{ fontFamily: "outfit-semibold" }} className="text-[#00A3AD]"> clients </span> 
            with the best 
            <span style={{ fontFamily: "outfit-semibold" }} className="text-[#00A3AD]"> professionals</span>"
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default MissionPage;
