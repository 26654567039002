import React, { useState } from "react";
import axios from "axios";
import Footer from "../Footer";

const Saloonreg = () => {
  const [formData, setFormData] = useState({
    salonName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://website-backend-lake.vercel.app/salon", formData);
      setSubmitted(true);
      setFormData({ salonName: "", email: "", phone: "", message: "" });
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-screen mt-8 bg-white py-16 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="max-w-2xl w-full">
          <div className="relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden border border-[#00A3AD]/20 p-8 md:p-12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <h3 className="text-3xl font-outfit-bold text-gray-900 mb-8 text-center">
                <span className="text-gradient bg-gradient-to-r from-[#00A3AD] to-[#008B94] bg-clip-text text-transparent">
                  Partner
                </span>{" "}
                With Us
              </h3>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-outfit-medium text-gray-600 mb-2">
                    Salon Name
                  </label>
                  <input
                    type="text"
                    name="salonName"
                    value={formData.salonName}
                    onChange={handleChange}
                    className="w-full px-4 py-3 text-sm rounded-lg border border-gray-200 focus:border-[#00A3AD] focus:ring-2 focus:ring-[#00A3AD]/30 transition-all outline-none"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-outfit-medium text-gray-600 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-3 text-sm rounded-lg border border-gray-200 focus:border-[#00A3AD] focus:ring-2 focus:ring-[#00A3AD]/30 transition-all outline-none"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-outfit-medium text-gray-600 mb-2">
                    Phone
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-3 text-sm rounded-lg border border-gray-200 focus:border-[#00A3AD] focus:ring-2 focus:ring-[#00A3AD]/30 transition-all outline-none"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-outfit-medium text-gray-600 mb-2">
                    Message
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    className="w-full px-4 py-3 text-sm rounded-lg border border-gray-200 focus:border-[#00A3AD] focus:ring-2 focus:ring-[#00A3AD]/30 transition-all outline-none"
                    required
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-br from-[#00A3AD] to-[#008B94] text-white font-outfit-medium py-3 rounded-lg transition-all duration-200 hover:scale-[1.02] hover:shadow-lg disabled:opacity-70"
              >
                {loading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent"></div>
                    <span>Submitting...</span>
                  </div>
                ) : (
                  "Submit Application"
                )}
              </button>

              {submitted && (
                <div className="flex items-center justify-center space-x-2 text-[#00A3AD] font-outfit-medium text-sm mt-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Thank you! We'll be in touch within 24 hours</span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Saloonreg;
