import React, { Suspense, lazy, useState } from "react";
import Footer from "../Footer";
import OurPartner from "./OurPartner";
import MalonPage from "./MalonPage";
import leftImage from "../image/websiteside1.webp";
import rightImage from "../image/websiteside2.webp";

const LazyOurservices = lazy(() => import("./Ourservices"));
const LazyForSaloon = lazy(() => import("./ForSaloon"));
const LazyFaq = lazy(() => import("./Faq"));

function Home() {
  const [email, setEmail] = useState("");
  const [notificationSent, setNotificationSent] = useState(false);

  const salonServices = [
    {
      title: "You and Me Unisex Salon",
      offer: "Limited Time Grab The Discount",
      maleServices: [
        "Haircut with Wash – @319",
        "Hair Spa + Facial – @1199",
        "Hair Cut at @150",
        "Hair Cut + Beard @200",
      ],
      femaleServices: [
        "Spa + Manicure + Pedicure – @1499",
        "D Tan + Wax + Threading – @899",
      ],
    },
    {
      title: "Indulge The Salon",
      offer: "Valid till 31st March",
      maleServices: [
        "Haircut with Beard – @199",
        "Get FLAT 50% OFF on all chemical work",
        "Get FLAT 30% OFF on Colour, Spa, Facial & clean-up services",
      ],
      femaleServices: [
        "Get FLAT 50% OFF on all chemical work",
        "Get FLAT 30% OFF on Colour, Spa, Facial & clean-up services",
      ],
    },
    {
      title: "You Unisex Salon",
      offer: "Limited Time Grab The Discount",
      maleServices: [
        "Beard set + Detan - @299",
        "Hair Cut + Hair Spa - @499",
        "Clean up + Detan - @599",
      ],
      femaleServices: [
        "Shine with out Hydra Facial with Complementary Detan  - flat @1499",
        "One Time Free Hair Cut Complementary on Hair spa - @999",
      ],
    },
  ];

  const handleNotifyMe = () => {
    console.log("Email submitted:", email);
    setNotificationSent(true);
    setTimeout(() => setNotificationSent(false), 3000);
  };

  const LoadingPlaceholder = () => (
    <div className="h-60 bg-white/10 rounded-xl animate-pulse max-w-7xl mx-auto" />
  );

  return (
    <>
      <div className="min-h-screen font-outfit relative overflow-hidden bg-[#00A3AD]">
        {/* Hero Section with pinned left & right images */}
        <div className="relative min-h-[70vh] flex items-center justify-center overflow-hidden">
          {/* Left Image pinned & rotated */}
          <img
            src={leftImage}
            alt="Left Decoration"
            className="
              hidden md:block
              absolute bottom-0
              left-[-16%]  
              w-96
              mb-9
              h-72
               transform
              rotate-[45deg]
            
            "
          />

          <img
            src={rightImage}
            alt="Right Decoration"
            className="hidden md:block absolute bottom-0 right-[-15%] w-96 h-auto  transform
              rotate-[-45deg]"
          />

          <div className="z-10 pt-12 pb-8 text-center">
            <h1
              style={{ fontFamily: "outfit-extrabold" }}
              className="text-3xl md:text-4xl text-white mb-6 leading-tight tracking-wide"
            >
              Book Salon Appointments with
              <br />
              <span className="pt-2 block">Zero Waiting Time</span>
            </h1>
            <p
              style={{ fontFamily: "outfit-medium" }}
              className="text-base md:text-lg text-white/90 mt-6 mb-8 max-w-2xl mx-auto"
            >
              Secure your spot at top-rated salons with instant confirmation.
              Enjoy premium services at your convenience.
            </p>
          </div>
        </div>

        {/* Services Section */}
        <div className="px-4 bg-[#00A3AD] relative z-10">
          <div className="max-w-7xl mx-auto">
            <div className="mb-8 text-center">
              <h2
                style={{ fontFamily: "outfit-bold" }}
                className="text-2xl md:text-3xl text-white mb-2"
              >
                Featured Salon Services
              </h2>
              <p
                style={{ fontFamily: "outfit-medium" }}
                className="text-white/80"
              >
                Swipe to explore ➡️
              </p>
            </div>

            {/* Mobile Carousel */}
            <div className="md:hidden overflow-x-auto pb-6 snap-x snap-mandatory [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
              <div className="flex gap-5 mt-7 px-4">
                {salonServices.map((service, index) => (
                  <div
                    key={index}
                    className="min-w-[15rem] snap-center bg-white rounded-3xl p-4 hover:scale-[1.02] transition-all duration-300 relative group"
                  >
                    <h3
                      style={{ fontFamily: "outfit-bold", fontSize: "1.3rem" }}
                      className="mb-2 text-gray-500"
                    >
                      {service.title}
                    </h3>
                    <h3
                      style={{ fontFamily: "outfit-regular", fontSize: "1rem" }}
                      className="mb-2 text-gray-500"
                    >
                      {service.offer}
                    </h3>

                    {/* Service Lists */}
                    <div className="space-y-1">
                      <div>
                        <div className="flex items-center gap-2 mb-2">
                          <span className="w-2 h-2 bg-[#00A3AD] rounded-full"></span>
                          <p
                            style={{ fontFamily: "outfit-semibold" }}
                            className="text-[#00A3AD]"
                          >
                            Men's Services
                          </p>
                        </div>
                        <ul className="space-y-2 pl-4">
                          {service.maleServices.map((item, i) => (
                            <li
                              key={i}
                              style={{ fontFamily: "outfit-regular" }}
                              className="text-gray-600 text-sm relative before:absolute before:-left-3 before:top-2 before:w-1.5 before:h-1.5 before:bg-[#00A3AD]/50 before:rounded-full"
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="pt-4 border-t border-dashed">
                        <div className="flex items-center gap-2 mb-2">
                          <span className="w-2 h-2 bg-[#00A3AD] rounded-full"></span>
                          <p
                            style={{ fontFamily: "outfit-semibold" }}
                            className="text-[#00A3AD]"
                          >
                            Women's Services
                          </p>
                        </div>
                        <ul className="space-y-2 pl-4">
                          {service.femaleServices.map((item, i) => (
                            <li
                              key={i}
                              style={{ fontFamily: "outfit-regular" }}
                              className="text-gray-600 text-sm relative before:absolute before:-left-3 before:top-2 before:w-1.5 before:h-1.5 before:bg-[#00A3AD]/50 before:rounded-full"
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div
                      className="absolute bottom-2 right-4 cursor-pointer transition-transform hover:scale-110"
                      onClick={() => {
                        const userAgent =
                          navigator.userAgent || navigator.vendor;
                        const isIOS = /iPad|iPhone|iPod/.test(userAgent);
                        const isMac = /Macintosh/.test(userAgent);
                        const isAndroid = /android/i.test(userAgent);

                        if (isIOS || isMac) {
                          window.location.href =
                            "https://apps.apple.com/in/app/malon/id6743373588";
                        } else if (isAndroid) {
                          window.location.href =
                            "https://play.google.com/store/apps/details?id=com.maloon&hl=en";
                        } else {
                          window.open(
                            "https://play.google.com/store/apps/details?id=com.maloon&hl=en",
                            "_blank"
                          );
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ fontWeight: "900" }}
                        className="h-5 w-5 text-[#333] drop-shadow-lg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop Grid */}
            <div className="hidden md:grid grid-cols-3 gap-5 mb-4 px-4">
              {salonServices.map((service, index) => (
                <div
                  key={index}
                  className="w-full bg-white rounded-3xl p-4 hover:scale-[1.02] transition-all duration-300 relative group"
                >
                  <h3
                    style={{ fontFamily: "outfit-bold", fontSize: "1.3rem" }}
                    className="mb-2 text-gray-500"
                  >
                    {service.title}
                  </h3>
                  <h3
                    style={{ fontFamily: "outfit-regular", fontSize: "1rem" }}
                    className="mb-2 text-gray-500"
                  >
                    {service.offer}
                  </h3>

                  {/* Service Lists */}
                  <div className="space-y-1">
                    <div>
                      <div className="flex items-center gap-2 mb-2">
                        <span className="w-2 h-2 bg-[#00A3AD] rounded-full"></span>
                        <p
                          style={{ fontFamily: "outfit-semibold" }}
                          className="text-[#00A3AD]"
                        >
                          Men's Services
                        </p>
                      </div>
                      <ul className="space-y-2 pl-4">
                        {service.maleServices.map((item, i) => (
                          <li
                            key={i}
                            style={{ fontFamily: "outfit-regular" }}
                            className="text-gray-600 text-sm relative before:absolute before:-left-3 before:top-2 before:w-1.5 before:h-1.5 before:bg-[#00A3AD]/50 before:rounded-full"
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="pt-4 border-t border-dashed">
                      <div className="flex items-center gap-2 mb-2">
                        <span className="w-2 h-2 bg-[#00A3AD] rounded-full"></span>
                        <p
                          style={{ fontFamily: "outfit-semibold" }}
                          className="text-[#00A3AD]"
                        >
                          Women's Services
                        </p>
                      </div>
                      <ul className="space-y-2 pl-4">
                        {service.femaleServices.map((item, i) => (
                          <li
                            key={i}
                            style={{ fontFamily: "outfit-regular" }}
                            className="text-gray-600 text-sm relative before:absolute before:-left-3 before:top-2 before:w-1.5 before:h-1.5 before:bg-[#00A3AD]/50 before:rounded-full"
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div
                    className="absolute bottom-2 right-4 cursor-pointer transition-transform hover:scale-110"
                    onClick={() => {
                      const userAgent = navigator.userAgent || navigator.vendor;
                      const isIOS = /iPad|iPhone|iPod/.test(userAgent);
                      const isMac = /Macintosh/.test(userAgent);
                      const isAndroid = /android/i.test(userAgent);

                      if (isIOS || isMac) {
                        window.location.href =
                          "https://apps.apple.com/in/app/malon/id6743373588";
                      } else if (isAndroid) {
                        window.location.href =
                          "https://play.google.com/store/apps/details?id=com.maloon&hl=en";
                      } else {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.maloon&hl=en",
                          "_blank"
                        );
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ fontWeight: "900" }}
                      className="h-5 w-5 text-[#333] drop-shadow-lg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Additional Sections */}
      <div className="space-y-16 py-12">
        <Suspense fallback={<LoadingPlaceholder />}>
          <LazyOurservices />
        </Suspense>

        <OurPartner />
        <Suspense fallback={<LoadingPlaceholder />}>
          <LazyForSaloon />
        </Suspense>
        <Suspense fallback={<LoadingPlaceholder />}>
          <LazyFaq />
        </Suspense>
      </div>

      <Footer />
    </>
  );
}

export default Home;
