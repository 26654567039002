import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import img3 from "./image/logo.jpg";
import img2 from "./image/playstore.png";
import img1 from "./image/apple.png";

const Footer = () => {
  const socialLinks = [
    ["https://www.instagram.com/malon.in/", <Instagram key="insta" className="h-5 w-5" />],
    ["https://www.linkedin.com/company/malon-in", <LinkedIn key="linkedin" className="h-5 w-5" />],
    ["https://x.com/Malon2065453117", <Twitter key="twitter" className="h-5 w-5" />],
  ];

  const quickLinks = [
    ["About Us", "/aboutus"],
    ["Privacy Policy", "/privacypolicy"],
    ["Terms & Conditions", "/termandcondition"],
    ["Contact Us", "/contactus"],
    ["Careers", "/careers"],
  ];

  return (
    <footer className="bg-[#121d23] text-white border-t-4 border-[#00A3AD]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Brand Section */}
          <div className="space-y-6 lg:pr-8">
            <Link to="/" className="inline-block">
              <motion.div whileHover={{ scale: 1.02 }}>
                <div className="flex items-center gap-3">
                  <img
                    src={img3}
                    alt="Malon Logo"
                    className="h-14 w-14 rounded-xl border-1 border-[#fff]"
                  />
                  <div>
                    <h2 style={{fontFamily:"outfit-regular"}}  className="text-xl">Malon</h2>
                    <p style={{fontFamily:"outfit-semibold"}} className="text-sm font-outfit-regular text-gray-400">
                      Salon Booking Platform
                    </p>
                  </div>
                </div>
              </motion.div>
            </Link>
            <p style={{fontFamily:"outfit-medium"}} className="text-gray-400 font-outfit-regular text-sm leading-relaxed">
              Revolutionizing beauty services through innovative technology and curated experiences.
            </p>
            <div className="flex space-x-3">
              {socialLinks.map(([url, icon], index) => (
                <motion.a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ y: -2 }}
                  className="p-2.5 bg-[#1a2a32] rounded-lg hover:bg-[#00A3AD] transition-colors"
                >
                  {icon}
                </motion.a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="lg:pl-8">
            <h3 className="font-outfit-semibold text-lg mb-6 text-[#00A3AD]">Explore</h3>
            <ul className="space-y-3">
              {quickLinks.map(([title, url], index) => (
                <motion.li key={index} whileHover={{ x: 5 }}>
                  <Link
                    to={url}
                    style={{fontFamily:"outfit-semibold"}}
                    className="font-outfit-regular text-gray-300 hover:text-[#00A3AD] flex items-center gap-2 transition-colors"
                  >
                    <div className="w-1.5 h-1.5 bg-[#00A3AD] rounded-full" />
                    {title}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </div>

          {/* App Downloads */}
          <div>
            <h3 style={{fontFamily:"outfit-medium"}}  className="text-lg mb-6 text-[#00A3AD]">Get the App</h3>
            <div className="space-y-4">
              <motion.a
                href="https://play.google.com/store/apps/details?id=com.maloon&hl=en"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -2 }}
                className="flex items-center bg-[#1a2a32] p-4 rounded-xl hover:bg-[#00A3AD]/10 transition-colors"
              >
                <div className="bg-white p-2 rounded-lg mr-4">
                  <img src={img2} alt="Google Play" className="h-7 w-7" />
                </div>
                <div>
                  <p style={{fontFamily:"outfit-regular"}}  className="text-xs text-gray-400">Available on</p>
                  <p style={{fontFamily:"outfit-medium"}}  className="text-white">Google Play</p>
                </div>
              </motion.a>
              <motion.a
                href="https://apps.apple.com/in/app/malon/id6743373588"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ y: -2 }}
                className="flex items-center bg-[#1a2a32] p-4 rounded-xl hover:bg-[#00A3AD]/10 transition-colors"
              >
                <div className="bg-black p-2 rounded-lg mr-4">
                  <img src={img1} alt="App Store" className="h-7 w-7" />
                </div>
                <div>
                  <p style={{fontFamily:"outfit-regular"}}  className="text-xs text-gray-400">Download on</p>
                  <p style={{fontFamily:"outfit-medium"}}  className="text-white">App Store</p>
                </div>
              </motion.a>
            </div>
          </div>

          {/* Contact */}
          <div className="lg:pl-8">
            <h3 style={{fontFamily:"outfit-medium"}}  className="text-lg mb-6 text-[#00A3AD]">Contact</h3>
            <div className="space-y-4">
              <p style={{fontFamily:"outfit-medium"}}  className="text-gray-400 text-sm leading-relaxed">
                Have questions or suggestions? We'd love to hear from you.
              </p>
              <a
              style={{fontFamily:"outfit-medium"}} 
                href="mailto:contactus@malon.in"
                className="font-outfit-regular text-[#00A3AD] hover:text-[#00A3AD]/80 transition-colors inline-block"
              >
                contactus@malon.in
              </a>
              <p style={{fontFamily:"outfit-regular"}}  className="text-gray-400 text-sm mt-4">
                Operational Hours:<br/>
                9AM - 7PM (IST)
              </p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="pt-8 border-t border-[#00A3AD]/20 text-center">
          <p className="text-gray-400 text-sm">
            © {new Date().getFullYear()} Malon. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;