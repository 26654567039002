import React from "react";
import ecobelleza from "../image/ecobelleza.jpg";
import nysalon from "../image/nysalon.jpg";
import youandme from "../image/youandme.jpg";
import yousalon from "../image/yousalon.jpg";
import indulge from "../image/indulge.jpg";

const partnerLogos = [ecobelleza, nysalon, youandme, yousalon, indulge];

const OurPartner = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-16 text-center">
          <h2 style={{fontFamily:"outfit-bold"}} className="text-4xl  text-gray-900 mb-4 font-outfit">
            Trusted by Industry Leaders
          </h2>
          <p style={{fontFamily:"outfit-medium"}} className="text-lg text-gray-600 max-w-2xl mx-auto">
            Partnered with the most innovative brands in beauty and wellness
          </p>
        </div>

        
        <div className="relative overflow-hidden group">
          
          <div className="absolute inset-y-0 left-0 w-24 bg-gradient-to-r from-white to-transparent z-10" />
          <div className="absolute inset-y-0 right-0 w-24 bg-gradient-to-l from-white to-transparent z-10" />

          
          <div className="flex animate-marquee whitespace-nowrap">
            {[...partnerLogos, ...partnerLogos].map((logo, index) => (
              <div
                key={index}
                className="mx-8 flex-shrink-0 transition-transform duration-300 hover:scale-105"
              >
                <div className="h-32 w-48 flex items-center justify-center p-4 bg-white rounded-lg border border-gray-100 hover:border-transparent shadow-sm hover:shadow-lg transition-all">
                  <img
                    src={logo}
                    alt={`Partner ${index + 1}`}
                    className="h-full w-full object-contain grayscale hover:grayscale-0 transition-all"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes marquee {
          0% { transform: translateX(0); }
          100% { transform: translateX(-50%); }
        }
        .animate-marquee {
          animation: marquee 25s linear infinite;
        }
      `}</style>
    </section>
  );
};

export default OurPartner;