import React from "react";
import logo from "../image/logo.jpg";
import malonqr from "../image/malonqr.png";
import AndroidIcon from "@mui/icons-material/Android"; // Import Android Icon

function MalonPage() {
  return (
    <section className="bg-[#00A3AD] text-white py-8 px-4 md:py-6">
      <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
        {/* Left Content */}
        <div className="md:w-1/2 flex flex-col items-start space-y-4">
          <div className="flex items-center gap-3">
            <img
              src={logo}
              alt="Malon Logo"
              className="h-12 w-12 rounded-xl border border-white"
            />
            <div className="flex flex-col">
              <span
                style={{ fontFamily: "outfit-medium" }}
                className="text-xl font-bold"
              >
                Malon
              </span>
              <span
                style={{ fontFamily: "outfit-regular" }}
                className="text-xs font-medium opacity-90"
              >
                Salon Booking Platform
              </span>
            </div>
          </div>

          <h2
            style={{ fontFamily: "outfit-medium" }}
            className="text-3xl md:text-4xl font-bold leading-tight"
          >
            Transform Your Beauty Experience
          </h2>
          
          <p
            style={{ fontFamily: "outfit-regular" }}
            className="text-base md:text-lg opacity-90 max-w-xl"
          >
            Download the Malon app for instant bookings, exclusive offers, and personalized salon recommendations.
          </p>

          {/* App Download Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 w-full mt-4">
            <button className="bg-white text-[#00A3AD] px-4 py-2 rounded-xl flex items-center gap-2 hover:bg-gray-100 transition-all">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.736-1.48 3.49-2.94 1.093-1.61 1.54-3.113 1.565-3.199-.039-.013-3.042-1.141-3.071-4.503-.026-3.36 2.736-4.945 2.876-5.026-1.572-2.351-4.021-2.402-4.787-2.436-2.156-.156-3.854 1.108-4.854 1.108zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.049-2.668.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.56-1.702z"/>
              </svg>
              <div className="text-left">
                <span
                  style={{ fontFamily: "outfit-regular" }}
                  className="text-xs font-medium"
                >
                  Download on the
                </span>
                <p
                  style={{ fontFamily: "outfit-medium" }}
                  className="font-semibold text-sm"
                >
                  App Store
                </p>
              </div>
            </button>

            <button className="bg-white text-[#00A3AD] px-4 py-2 rounded-xl flex items-center gap-2 hover:bg-gray-100 transition-all">
              <AndroidIcon className="w-5 h-5" />
              <div className="text-left">
                <span
                  style={{ fontFamily: "outfit-regular" }}
                  className="text-xs font-medium"
                >
                  Get it on
                </span>
                <p
                  style={{ fontFamily: "outfit-medium" }}
                  className="font-semibold text-sm"
                >
                  Google Play
                </p>
              </div>
            </button>
          </div>
        </div>

        {/* Right QR Code */}
        <div className="md:w-1/2 flex justify-center md:justify-end">
          <div className="relative bg-[#00A3AD] mt-16 ml-10">
            <img
              src={malonqr}
              alt="Download QR Code"
              className="w-96 h-auto"
            />
            <p
              style={{ fontFamily: "outfit-medium" }}
              className="text-center mt-2 text-[#fff] font-medium text-sm"
            >
              Scan to download
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MalonPage;
