import { useState, useEffect } from "react";

const OfferCarousel = () => {
  const offers = [
    {
      id: 1,
      text: "Get 10% off on total bill for first-time users. Use coupon WELCOME10",
    },
    {
      id: 2,
      text: "Get up to 20% off on total bill after your first booking",
    },
  ];

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((prev) => (prev + 1) % offers.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [offers.length]);

  return (
    <div className="relative p-2 bg-[#fff] text-white text-center text-xs font-outfit-medium w-full">
      <div className="overflow-hidden">
        <div style={{fontFamily:"outfit-medium",fontSize:"1rem",color:"#121314"}} className="transition-all duration-500">{offers[current].text}</div>
      </div>
    </div>
  );
};

export default OfferCarousel;
